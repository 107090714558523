<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                class="text-body"
                icon="BellIcon"
                size="21"
                :badge="unreadCount"
                badge-classes="bg-danger"
            />
        </template>

        <li class="dropdown-menu-header" style="height: 300px">
            <Notification />
        </li>
    </b-nav-item-dropdown>

    
</template>

<script>
import Notification from '@/@core/components/dashboard/Notification.vue';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    components: {
        Notification,
    },

    data() {
        return {
            unreadCount: '',
            config: useAppConfig(),
        };
    },

    watch: {
        'config.refreshNotification.value': {
            handler() {
                this.getUnreadNotifications();
            },
        },
    },

    methods: {
        getUnreadNotifications() {
            this.$useJwt
                .getNotificationsList({params: {read: false}})
                .then(res => {
                    const {count} = res.data;

                    this.unreadCount = count;
                });
        },
    },
    mounted() {
        this.getUnreadNotifications();
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/datepicker.scss';
</style>
