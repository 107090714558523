export default [
    {
        title: 'Sidebar.Dashboard',
        route: 'dashboard',
        icon: 'home',
    },
    {
        title: 'Sidebar.Duplicates',
        route: 'duplicates',
        icon: 'check-all',
    },
    {
        title: 'Sidebar.Settings',
        route: 'settings',
        icon: 'recycle',
    },
    {
        title: 'Sidebar.Subscription',
        route: 'subscription',
        icon: 'view-dashboard',
    },
    {
        title: 'Sidebar.EventLog',
        route: 'eventLog',
        icon: 'logout',
    },
    {
        title: 'Sidebar.Users',
        route: 'users',
        icon: 'account-group',
    },
    {
        title: 'Sidebar.BillingPage',
        route: 'billing',
        icon: 'wallet',
    },
    {
        title: 'Sidebar.Faq',
        route: 'faq',
        icon: 'help-circle',
    },
];
