var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow bg-secondaryColor",class:[
        {
            expanded:
                !_vm.isVerticalMenuCollapsed ||
                (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
        },
        _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item d-flex justify-content-center"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/duplioAsset/logo.png")}})])])],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{'d-block': _vm.shallShadowBottom}}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area mt-2",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
                _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
            }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main text-white bg-secondaryColor",attrs:{"items":_vm.navMenuItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }