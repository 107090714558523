<template>
    <div
        class="app-content content"
        :class="[
            {'show-overlay': $store.state.app.shallShowOverlay},
            $route.meta.contentClass,
        ]"
    >
        <div class="content-overlay" />
        <div class="header-navbar-shadow" />
        <div
            class="content-wrapper"
            :class="contentWidth === 'boxed' ? 'container p-0' : null"
        >
            <div
                v-if="visible"
                class="w-full d-flex p-1 banner mb-2 align-items-center"
                style="gap: 1rem"
            >
                <div class="imga">
                    <b-img
                        src="@/assets/duplioAsset/icons/alert.png"
                        blank-color="#ccc"
                        width="64"
                        alt="placeholder"
                    />
                </div>

                <b-card-text
                    class="mb-0 d-flex text-black font-weight-bold flex-column"
                    style="gap: 0.5rem"
                >
                    <div>
                        <span>{{ $t(locale.reconnectMessage) }}</span>
                    </div>

                    <div class="d-flex mt-1 justify-content-end">
                        <b-button
                            :href="auth_link"
                            class="text-end"
                            variant="primary"
                            v-text="$t(locale.reConnect)"
                        />
                    </div>
                </b-card-text>
            </div>

            <slot name="breadcrumb">
                <app-breadcrumb />
            </slot>
            <div class="content-body">
                <transition :name="routerTransition" mode="out-in">
                    <slot />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    data() {
        return {
            visible: false,
            auth_link: '',
            locale: {
                reconnectMessage: 'Message.reconnectMessage',
                reConnect: 'Message.reConnect',
            },
        };
    },
    components: {
        AppBreadcrumb,
    },

    mounted() {
        this.$useJwt.subscription().then(res => {
            const {failed_to_refresh_token} = res.data.data[0];

            if (failed_to_refresh_token) {
                const {auth_link} = res.data.data[0];
                this.visible = true;

                this.auth_link = auth_link;
            } else {
                this.visible = false;
                this.auth_link = '';
            }
        });
    },

    setup() {
        const {routerTransition, contentWidth} = useAppConfig();

        return {
            routerTransition,
            contentWidth,
        };
    },
};
</script>

<style scoped>
.translucent-wrap {
    height: 110px;
    position: relative;
}

.banner {
    background-color: rgba(79, 207, 192, 40%);
    border: 2px solid rgba(79, 207, 192, 1);
    border-radius: 25px;
}
</style>
