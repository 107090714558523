<template>
    <b-modal
        id="modal-prevent-notifications"
        modal-class="modal-primary"
        ok-variant="danger"
        ref="modal"
        :ok-title="$t(locale.delete)"
        ok-only
        @ok="deleteNotification"
        :title="$t(locale.title)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
    >
        <b-card-text>
            <Loading v-if="loadingState" />
            <div
                v-if="showNotification"
                class="p-2 d-flex flex-column"
                style="gap: 1.5rem"
            >
                <div class="">
                    <p class="h6" v-text="$t('Notification.date')" />
                    <p
                        class="mb-0 pb-0"
                        v-text="formatDate(notification.received)"
                    />
                </div>

                <div class="">
                    <p class="h6" v-text="$t('Notification.subject')" />
                    <p class="mb-0 pb-0" v-text="notification[subject]" />
                </div>

                <div class="">
                    <p class="h6" v-text="$t('Notification.message')" />
                    <p class="mb-0 pb-0" v-text="notification[message]" />
                </div>
            </div>
        </b-card-text>
    </b-modal>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import {dateMixins} from '@/mixins/dateMixins';

export default {
    mixins: [dateMixins],
    data() {
        return {
            openModal: true,
            config: useAppConfig(),
            notification: '',
            showNotification: false,
            loadingState: true,
            locale: {
                title: 'HomeCard.Notification',
                delete: 'HomeCard.Delete',
            },
        };
    },

    watch: {
        uuid(uuid) {
            if (uuid) this.getNotification(uuid);
        },
    },

    computed: {
        uuid() {
            return this.$store.getters['appConfig/uuidNotification'];
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        subject() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_subject' : 'eng_subject';
            } else return this.getLang == 'sv' ? 'swe_subject' : 'eng_subject';
        },

        resultMsg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },

        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        closeModal() {
            const {storeUuidNotification, refreshNotification} = useAppConfig();

            if (this.notification.read == false) {
                this.$useJwt
                    .updateNotifications(this.uuid, {read: true})
                    .then(res => {
                        refreshNotification.value = true;
                    });
            }

            storeUuidNotification.value = '';

            this.showNotification = false;
            this.loadingState = true;
        },
        deleteNotification(event) {
            const {refreshNotification} = useAppConfig();
            event.preventDefault();

            this.$useJwt.deleteNotifications(this.uuid).then(res => {
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.resultMsg],
                    'CheckIcon',
                    'success'
                );
                this.$refs['modal'].hide();
                refreshNotification.value = true;
            });
        },

        getNotification(uuid) {
            this.$useJwt
                .getNotificationsById(uuid)
                .then(res => {
                    this.notification = res.data;
                })
                .finally(() => {
                    this.showNotification = true;
                    this.loadingState = false;
                });
        },
    },
};
</script>

<style></style>
