<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ userData.fullName || userData.username }}
                </p>
                <span class="user-status">{{ userData.role }}</span>
            </div>
            <b-avatar
                size="40"
                :src="currentUsers.profile_picture"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success"
            >
            </b-avatar>
        </template>

        <b-dropdown-item
            v-for="(item, index) in dataDropdown"
            :key="index"
            :to="{name: item.link}"
            link-class="d-flex align-items-center"
        >
            <feather-icon
                :size="item.iconSize"
                :icon="item.icon"
                class="mr-50"
            />
            <span class="text-truncate" v-text="$t(item.text)" />
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span> {{ $t('UserDropdown.Logout') }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt';
import {avatarText} from '@core/utils/filter';

export default {
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            avatarText,
            dataDropdown: [
                {
                    link: 'acountSettings',
                    icon: 'UserIcon',
                    iconSize: '20',
                    text: 'UserDropdown.AcountSettings',
                },
                {
                    link: 'settings',
                    icon: 'SettingsIcon',
                    iconSize: '16',
                    text: 'UserDropdown.Settings',
                },
                {
                    link: 'billing',
                    icon: 'CreditCardIcon',
                    iconSize: '16',
                    text: 'UserDropdown.Billing',
                },
                {
                    link: 'faq',
                    icon: 'HelpCircleIcon',
                    iconSize: '16',
                    text: 'UserDropdown.Faq',
                },
            ],
        };
    },
    computed: {
        currentUsers() {
            return this.$store.getters['appConfig/currentUser'];
        },
    },
    methods: {
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
                useJwt.jwtConfig.storageRefreshTokenKeyName
            );

            // Remove userData from localStorage
            localStorage.removeItem('userData');

            // Redirect to login page
            this.$router.push({name: 'login'});
        },
    },
};
</script>
