<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            {{ $t('AppFooter.copyright') }} © {{ new Date().getFullYear() }}
            <b-link class="ml-25">Duplio</b-link>
            <span class="d-none d-sm-inline-block">
                , {{ $t('AppFooter.reserved') }}
            </span>
        </span>
    </p>
</template>

<script>
export default {};
</script>
