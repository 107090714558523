<template>
    <b-modal
        id="modal-prevent-closing"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        :title="$t(locale.title)"
        ok-only
        ref="modal"
        :ok-title="$t(locale.send)"
        centered
        @close="closeModal"
        @hide="closeModal"
        @ok="submitSupport"
        clickToClose
    >
        <b-card-text
            class="d-flex flex-column justify-content-center"
            style="gap: 1rem"
        >
            <div class="">
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col cols="12" md="6">
                            <ValidationProvider
                                #default="{errors}"
                                rules="required"
                                :name="$t(locale.notifSubject)"
                            >
                                <b-form-input
                                    id="login-email"
                                    v-model="dataForm.subject"
                                    :placeholder="$t(locale.notifSubject)"
                                />

                                <small class="text-danger" v-text="errors[0]" />
                            </ValidationProvider>
                        </b-col>
                    </b-row>

                    <ValidationProvider
                        rules="required"
                        #default="{errors}"
                        :name="$t(locale.msg)"
                    >
                        <b-form-group
                            class="mt-2"
                            :label="$t(locale.msgPlaceholder)"
                        >
                            <b-form-textarea
                                id="textarea-rows"
                                v-model="dataForm.message"
                                :placeholder="$t(locale.msgPlaceholder)"
                                rows="8"
                            />
                            <small class="text-danger" v-text="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </ValidationObserver>

                <!-- <b-button
                        @click="submitSupport"
                        variant="primary"
                        :disabled="loadingStatus"
                    >
                        <span v-if="loadingStatus">
                            <b-spinner small type="grow"></b-spinner>
                            {{ $t(locale.loading) }}...
                        </span>

                        <span v-else>
                            {{ $t(locale.send) }}
                        </span>
                    </b-button> -->
            </div>
        </b-card-text>
    </b-modal>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    props: {
        supportModal: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            loadingStatus: false,
            config: useAppConfig(),
            dataForm: {
                subject: '',
                message: '',
            },
            locale: {
                title: 'EventLogCard.title',
                send: 'UsersCard.send',
                notifSubject: 'Notification.subject',
                title: 'SupportCard.title',
                msgPlaceholder: 'SupportCard.messagePlaceholder',
                msg: 'SupportCard.msg',
                loading: 'Message.loading',
            },
        };
    },
    computed: {
        openModal: {
            get() {
                return this.supportModal;
            },
            set(value) {
                //
            },
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        closeModal() {
            this.$emit('supportModal', false);
        },
        submitSupport(event) {
            event.preventDefault();

            this.$refs.form.validate().then(res => {
                if (res) {
                    this.$useJwt
                        .openTicket(this.dataForm)
                        .then(res => {
                            this.$nextTick(() => {
                                this.$refs['modal'].toggle('#toggle-btn');
                            });

                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.message],
                                'CheckIcon',
                                'success'
                            );
                        })
                        .finally(() => {
                            this.$refs.form.reset();
                            this.dataForm = {
                                subject: '',
                                message: '',
                            };
                        });
                }
            });
        },
    },
};
</script>
